<template>
    <div>
        <router-link :to="to" :target="target">
            <b-card class="icon mb-4">
                <b-card-body>
                    <font-awesome-icon :icon="[prefix, icon]" :size="iconSize"/>
                </b-card-body>
                <b-card-title><b-card-title>{{title}}</b-card-title></b-card-title>
            </b-card>
        </router-link>
        <div class="w-100 d-none d-sm-block d-md-none"/>
    </div>
</template>
<script>

import {Component, Vue, Prop} from 'vue-property-decorator';

@Component
export default class IconLink extends Vue {
    @Prop({type: String}) prefix;
    @Prop({type: String}) icon;
    @Prop({type: String, default: '6x'}) iconSize;
    @Prop({type: String}) title;
    @Prop({type: [Object, String], default: '/admin'}) to;
    @Prop({type: String, default: undefined}) target;
}

</script>
<style scoped>
    div.icon {
        width: 200px;
        border: 0;
        cursor: pointer;
        color: #A28058 !important;
    }

    div.icon:hover {
        color: #141928 !important;
    }

</style>
