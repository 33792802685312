<template>
    <b-row>
        <b-col :class="!pageSelected ? 'icons' : ''">
            <template v-if="pageSelected">
                <router-view/>
            </template>
            <template v-else>
                <b-card-group deck>
                    <icon-link prefix="fas"
                               icon="hammer"
                               title="On-the-Job Training"
                               :to="{name: 'ojts', params: {}}" />
                    <icon-link prefix="fas"
                               icon="walking"
                               title="Mentorship Activities"
                               :to="{name: 'mas', params: {}}" />
                    <icon-link prefix="fas"
                               icon="people-carry"
                               title="Custom Activities"
                               :to="{name: 'customActivities', params: {}}"/>
                </b-card-group>
            </template>
        </b-col>
    </b-row>
</template>
<script>
import {Component, Vue} from 'vue-property-decorator';
import Breadcrumb from '@/views/menu/breadcrumb/breadcrumb';
import IconLink from '@/views/private/shared/IconLink.vue';
import store from '@/store/store';
import _ from 'underscore';
import {User} from '@/model/user';

function isPageSelected(pageName) {
    return _.contains(['ojts', 'mas', 'customActivities'], pageName);
}


function routeHandler(to, from, next) {

    const activeUser = store.getters['userSession/getUser'];

    if (!(activeUser instanceof User) || activeUser.isATrainee()) {
        return next(false);
    }


    const BASE_CRUMBS = [
        Breadcrumb.create('Administration', {name: 'adminMain'}, false)
    ];

    if (activeUser.isAnAdministrator() || activeUser.isAnInstructor()) {
        BASE_CRUMBS.push(Breadcrumb.create('Activities', {name: 'activities'}, !isPageSelected(to.name)));
    }

    store.commit('setBreadcrumbs', _.clone(BASE_CRUMBS));
    next();
}

@Component({
    components: {
        IconLink
    }
})
export default class Activities extends Vue {

    get pageSelected() {
        return isPageSelected(this.$route.name);
    }

    get activeUser() {
        return this.$store.getters['userSession/getUser'];
    }

    beforeRouteEnter(to, from, next) {
        routeHandler(to, from, next);
    }

    beforeRouteUpdate(to, from, next) {
        routeHandler(to, from, next);
    }
}
</script>
<style scoped>

</style>
